.gallery {
    padding: 1em 0;
    margin: 0;
}

.gallery_header {
    padding: 0 1em;
    margin: 0;
}

.gallery_body {
    padding: 0;
    margin: 0;
}