.nav {
    display: inline-block;
    position: relative;
  }
  
  .nav_wrapper {
    display: inline-block;
    position: relative;
    width: 20em;
    left: 0;
    margin: 0;
    padding: 0;
    text-align: left;
    overflow: hidden;
    background-color: rgba(232, 232, 232, 0.78);
  }
  
  .nav_wrapper__hide {
    display: none;
  }
  
  .nav_tab {
    position: absolute;
    text-align: right;
    top: -1em;
    right: 21em;
    transform: rotate(270deg);
    transform-origin: right;
    white-space: nowrap;
    user-select: none;
  }

  
.nav_tab::before {
    content: '';
    position: relative;
    top: -1.5em;
    border-bottom: 2em solid rgba(232, 232, 232, 0.78);
    border-left: 2em solid transparent;
  }
  
  .nav_tab__hide {
    top: -2em;
    right: 1em;
  }