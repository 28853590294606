@import url(https://fonts.googleapis.com/css?family=Yanone+Kaffeesatz&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* > * {
  /*border: 1px solid grey;*/
  box-sizing: border-box;
}

#root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-image: url(/static/media/bg.a4595829.svg);
  background-size: 20em;
  text-align: center;
  font-family: "Yanone Kaffeesatz", sans-serif;
}

.layout {
  display: inline-block;
  text-align: right;
  width: 64em;
  font-size: 1em;

  border-left: 1px solid rgba(0, 0, 0, 0.20);
  border-right: 1px solid rgba(0, 0, 0, 0.20);
  background-color: rgba(0, 0, 0, 0.10);

  margin: 0;
  padding: 0;
  vertical-align: bottom;
}

.header {
  width: 100%;

  margin: 0;
  padding: 0;
}

.main {
  float: left;
  width: 43em;
  height: 100%;
  text-align: left;
  margin: 0;
  padding: 0;
}

.main__full {
  width: 100%;
}

.tab {
  display: inline-block;
  cursor: pointer;
  background-color: rgba(232, 232, 232, 0.78);;
  line-height: 2em;
  padding: 0 2em;
}

.tab_text {
  display: inline-block;
  padding: 0;
}

.info {
  text-align: center;
  margin: 0;
  padding: 1em 0;
}

.info_fio {
  padding: 0;
  margin: 0;
}

.info_lastname {
  font-size: 1.4em;
  padding: 0;
  margin: 0;
}

.info_firstname {
  font-size: 1em;
  padding: 0;
  margin: 0;
}

.info_life {
  padding: 2em 0 0 0;
  margin: 0;
}

.info_age {
  font-size: 1.4em;
  padding: 0;
  margin: 0;
}

.info_logo {
  display: inline-block;
  text-align: center;
  padding: 1em 0;
}

.link {
  text-decoration: none;
  padding: 0;
  margin: 0;
}

.link:hover {
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.57);
  cursor: pointer;
}

/* unvisited link */
.link:link {
  color: #000;
}

/* visited link */
.link:visited {
  color: #000;
}

/* selected link */
.link:active {
  color: gray;
}
.avatar {
    display: inline-block;
    padding: 0;
    margin: 0;
    width: 8em;
    height: 8em;
    overflow: hidden;
    border-radius: 50%;
    background-repeat: no-repeat;
    -webkit-filter: drop-shadow(-1px 3px 2px rgba(0, 0, 0, 0.57));
            filter: drop-shadow(-1px 3px 2px rgba(0, 0, 0, 0.57));
  }
  

.menu {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .menu_item {
    vertical-align: bottom;
    padding: 0;
    margin: 0;
  }

  .menu_wrapper {
    display: block;
    padding: 1em;
    margin: 0;
  }
  
  .menu_wrapper__active {
    background-color: rgba(211, 211, 211, 0.5);
  }
  
  .menu_logo {
    display: inline-block;
    width: 3em;
    height: 3em;
    vertical-align: middle;
    background-repeat: no-repeat;
    opacity: 0.5;
    -webkit-filter: drop-shadow(-1px 3px 2px rgba(0, 0, 0, 0.57));
            filter: drop-shadow(-1px 3px 2px rgba(0, 0, 0, 0.57));
    margin: 0;
    padding: 0;
  }
  
  .menu_logo__book {
    background-image: url(/static/media/book-open-solid.cd3cb58a.svg);
  }
  
  .menu_logo__gallery {
    background-image: url(/static/media/images-solid.6873bb5f.svg);
  }
  
  .menu_logo__map {
    background-image: url(/static/media/map-solid.67b896c3.svg);
  }
  
  .menu_logo__phone {
    background-image: url(/static/media/mobile-alt-solid.0c6a5d8d.svg);
    background-position-x: 0.5em;
  }
  
  .menu_logo__email {
    background-image: url(/static/media/at-solid.9571ceae.svg);
  }
  
  .menu_logo__date {
    background-image: url(/static/media/calendar-alt-solid.c0735707.svg);
    background-position-x: 0.2em;
  }
  
  .menu_item-name {
    display: inline-block;
    font-weight: bold;
    vertical-align: middle;
    padding: 0 0 0 1em;
    width: 80%;
  }
  
  .menu_item-name-descripton {
    font-weight: normal;
    font-size: 1.4em;
    padding: 0.3em 0 0 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }
.nav {
    display: inline-block;
    position: relative;
  }
  
  .nav_wrapper {
    display: inline-block;
    position: relative;
    width: 20em;
    left: 0;
    margin: 0;
    padding: 0;
    text-align: left;
    overflow: hidden;
    background-color: rgba(232, 232, 232, 0.78);
  }
  
  .nav_wrapper__hide {
    display: none;
  }
  
  .nav_tab {
    position: absolute;
    text-align: right;
    top: -1em;
    right: 21em;
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
    -webkit-transform-origin: right;
            transform-origin: right;
    white-space: nowrap;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }

  
.nav_tab::before {
    content: '';
    position: relative;
    top: -1.5em;
    border-bottom: 2em solid rgba(232, 232, 232, 0.78);
    border-left: 2em solid transparent;
  }
  
  .nav_tab__hide {
    top: -2em;
    right: 1em;
  }
.bio {
    padding: 1em 0;
    margin: 0;
}

.bio_header {
    padding: 0 1em;
    margin: 0;
}

.bio_body {
    padding: 0 1em;
    margin: 0;
}
.gallery {
    padding: 1em 0;
    margin: 0;
}

.gallery_header {
    padding: 0 1em;
    margin: 0;
}

.gallery_body {
    padding: 0;
    margin: 0;
}
.map {
    padding: 1em 0;
    margin: 0;
}

.map_header {
    padding: 0 1em;
    margin: 0;
}

.map_body {
    padding: 0;
    margin: 0;
}
.tree {
    padding: 1em 0;
    margin: 0;
}

.tree_header {
    padding: 0 1em;
    margin: 0;
}

.tree_body {
    padding: 0;
    margin: 0;
}
