
.menu {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .menu_item {
    vertical-align: bottom;
    padding: 0;
    margin: 0;
  }

  .menu_wrapper {
    display: block;
    padding: 1em;
    margin: 0;
  }
  
  .menu_wrapper__active {
    background-color: rgba(211, 211, 211, 0.5);
  }
  
  .menu_logo {
    display: inline-block;
    width: 3em;
    height: 3em;
    vertical-align: middle;
    background-repeat: no-repeat;
    opacity: 0.5;
    filter: drop-shadow(-1px 3px 2px rgba(0, 0, 0, 0.57));
    margin: 0;
    padding: 0;
  }
  
  .menu_logo__book {
    background-image: url(./assets/book-open-solid.svg);
  }
  
  .menu_logo__gallery {
    background-image: url(./assets/images-solid.svg);
  }
  
  .menu_logo__map {
    background-image: url(./assets/map-solid.svg);
  }
  
  .menu_logo__phone {
    background-image: url(./assets/mobile-alt-solid.svg);
    background-position-x: 0.5em;
  }
  
  .menu_logo__email {
    background-image: url(./assets/at-solid.svg);
  }
  
  .menu_logo__date {
    background-image: url(./assets/calendar-alt-solid.svg);
    background-position-x: 0.2em;
  }
  
  .menu_item-name {
    display: inline-block;
    font-weight: bold;
    vertical-align: middle;
    padding: 0 0 0 1em;
    width: 80%;
  }
  
  .menu_item-name-descripton {
    font-weight: normal;
    font-size: 1.4em;
    padding: 0.3em 0 0 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }