.bio {
    padding: 1em 0;
    margin: 0;
}

.bio_header {
    padding: 0 1em;
    margin: 0;
}

.bio_body {
    padding: 0 1em;
    margin: 0;
}