.tree {
    padding: 1em 0;
    margin: 0;
}

.tree_header {
    padding: 0 1em;
    margin: 0;
}

.tree_body {
    padding: 0;
    margin: 0;
}