* > * {
  /*border: 1px solid grey;*/
  box-sizing: border-box;
}

#root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

@import url("https://fonts.googleapis.com/css?family=Yanone+Kaffeesatz&display=swap");

.html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-image: url(./assets/bg.svg);
  background-size: 20em;
  text-align: center;
  font-family: "Yanone Kaffeesatz", sans-serif;
}

.layout {
  display: inline-block;
  text-align: right;
  width: 64em;
  font-size: 1em;

  border-left: 1px solid rgba(0, 0, 0, 0.20);
  border-right: 1px solid rgba(0, 0, 0, 0.20);
  background-color: rgba(0, 0, 0, 0.10);

  margin: 0;
  padding: 0;
  vertical-align: bottom;
}

.header {
  width: 100%;

  margin: 0;
  padding: 0;
}

.main {
  float: left;
  width: 43em;
  height: 100%;
  text-align: left;
  margin: 0;
  padding: 0;
}

.main__full {
  width: 100%;
}

.tab {
  display: inline-block;
  cursor: pointer;
  background-color: rgba(232, 232, 232, 0.78);;
  line-height: 2em;
  padding: 0 2em;
}

.tab_text {
  display: inline-block;
  padding: 0;
}

.info {
  text-align: center;
  margin: 0;
  padding: 1em 0;
}

.info_fio {
  padding: 0;
  margin: 0;
}

.info_lastname {
  font-size: 1.4em;
  padding: 0;
  margin: 0;
}

.info_firstname {
  font-size: 1em;
  padding: 0;
  margin: 0;
}

.info_life {
  padding: 2em 0 0 0;
  margin: 0;
}

.info_age {
  font-size: 1.4em;
  padding: 0;
  margin: 0;
}

.info_logo {
  display: inline-block;
  text-align: center;
  padding: 1em 0;
}

.link {
  text-decoration: none;
  padding: 0;
  margin: 0;
}

.link:hover {
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.57);
  cursor: pointer;
}

/* unvisited link */
.link:link {
  color: #000;
}

/* visited link */
.link:visited {
  color: #000;
}

/* selected link */
.link:active {
  color: gray;
}